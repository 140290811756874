import Bridge from "@/utils/bridge";

export function clientBrowser() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //ios
    return "ios";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    //android
    return "android";
  }
}

// 获取token
export function handleToken() {
  return new Promise((resolve) => {
    const client = clientBrowser();
    // 本地开发时，设置token，并取消注释
    // resolve(
    //   "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyaWQiOiI2MTgiLCJsb2dpbl91c2VyX2tleSI6ImZjNzk5NDJmLTNjNzktNDcyYy05ZTFjLWI0ZmMyY2YzMmM1OCJ9.OGu6rzLRndsHT73AAEZQoV4RhgSz2yMqfgbeTvMHrGy6rmFf3KLAuWnhAoUHAgnQUqpa9Ij93rM08PLj5G_mdw"
    // );
    if (client == "ios") {
      Bridge.callhandler("getIosToken", {}, (token) => {
        resolve(token);
      });
    } else if (client == "android") {
      if (window.LoanMarketChina) {
        let token = window.LoanMarketChina.getAuthorization();
        resolve(token);
      }
    }
  });
}
